// @flow
import * as React from 'react';
import SvgIcon, { type SvgIconProps } from '@material-ui/core/SvgIcon';

export type Props = SvgIconProps;

const ArrowIcon = ({ ...props }: Props): React.Node => (
  <SvgIcon width="14px" height="10px" viewBox="0 0 14 10" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.537.808a.5.5 0 01.817-.162l4 4a.5.5 0 010 .708l-4 4a.5.5 0 11-.708-.708L11.793 5.5H1a.5.5 0 010-1h10.793L8.646 1.354a.5.5 0 01-.109-.546z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default React.memo<Props>(ArrowIcon);
