// @flow
import type { Styles } from './types';

const styles: Styles = unusedTheme => ({
  root: {
    width: '100%',
    height: 'auto',
  },
});

export default styles;
