// @flow
import * as React from 'react';
import SvgIcon, { type SvgIconProps } from '@material-ui/core/SvgIcon';

export type Props = SvgIconProps;

const FacebookIcon = ({ ...props }: Props): React.Node => {
  return (
    <SvgIcon width="26px" height="26px" viewBox="0 0 26 26" {...props}>
    <circle cx="13" cy="13" r="13" />
    <path d="M14.5844 8.97812H16.25V6.5H14.2797C11.9031 6.60156 11.4156 7.94219 11.375 9.34375V10.5828H9.75V13H11.375V19.5H13.8125V13H15.8234L16.2094 10.5828H13.8125V9.83125C13.806 9.72428 13.8209 9.6171 13.8564 9.51597C13.8918 9.41484 13.9471 9.32182 14.019 9.24235C14.0909 9.16289 14.178 9.09858 14.275 9.05321C14.3721 9.00784 14.4773 8.98231 14.5844 8.97812Z" fill="white" />
    </SvgIcon>
  );
};

export default React.memo<Props>(FacebookIcon);
