// @flow

import * as React from 'react';
import ThemeProvider from '@material-ui/styles/ThemeProvider';

import theme from '../../config/material-ui/theme';
import AppIntlProvider from '~plugins/i18n/components/AppIntlProvider';
import MuiBaseline from '../../config/material-ui/components/MuiBaseline';
import AppHelmet from '~plugins/helmet/components/AppHelmet';
import PreviewProvider from '~plugins/prismic/components/PreviewProvider';
import Snackbar from '~components/Snackbar';
import SnackbarProvider from '~plugins/material-ui/modules/snackbar/components/SnackbarProvider';
import PageContext, { type Page$Context } from '~plugins/page-context';
import BaseLayout from './BaseLayout';
import FontsHelmet from '../../config/material-ui/fonts/FontsHelmet';

export type Props = {
  children: React.Node,
  pageContext: Page$Context,
};

const Layout = ({ children, ...props }: Props) => (
  <AppIntlProvider {...props}>
    <ThemeProvider theme={theme}>
      <PreviewProvider>
        {/* eslint-disable-next-line react/destructuring-assignment */}
        <PageContext.Provider value={props.pageContext}>
          <SnackbarProvider Snackbar={Snackbar}>
            <AppHelmet {...props} />
            <FontsHelmet />
            <BaseLayout>{children}</BaseLayout>
          </SnackbarProvider>
        </PageContext.Provider>
        <MuiBaseline />
      </PreviewProvider>
    </ThemeProvider>
  </AppIntlProvider>
);

export default Layout;
