// @flow
import * as React from 'react';
import SvgIcon, { type SvgIconProps } from '@material-ui/core/SvgIcon';

export type Props = SvgIconProps;

const ProfileButton = ({ ...props }: Props): React.Node => (
  <SvgIcon width="28px" height="28px" viewBox="0 0 28 28" {...props}>
    <path
      d="M13.9997 2.33334C7.55967 2.33334 2.33301 7.56 2.33301 14C2.33301 20.44 7.55967 25.6667 13.9997 25.6667C20.4397 25.6667 25.6663 20.44 25.6663 14C25.6663 7.56 20.4397 2.33334 13.9997 2.33334ZM13.9997 5.83334C15.9363 5.83334 17.4997 7.39667 17.4997 9.33334C17.4997 11.27 15.9363 12.8333 13.9997 12.8333C12.063 12.8333 10.4997 11.27 10.4997 9.33334C10.4997 7.39667 12.063 5.83334 13.9997 5.83334ZM13.9997 22.4C11.083 22.4 8.50467 20.9067 6.99967 18.6433C7.03467 16.3217 11.6663 15.05 13.9997 15.05C16.3213 15.05 20.9647 16.3217 20.9997 18.6433C19.4947 20.9067 16.9163 22.4 13.9997 22.4Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default React.memo<Props>(ProfileButton);
