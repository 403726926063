// @flow
import type { Styles } from './types';

const styles: Styles = theme => ({
  root: {
    background: theme.palette.common.white,
    position: 'fixed',
    width: '100%',
    top: 0,
    borderBottom: '1px solid #12121214',
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.shorter,
    }),
  },
  siteLogo: {
    marginRight: 'auto',
    maxWidth: '170px',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '150px',
    },
  },
  actionsRight: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '8px',
  },
  actionsRightWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '1.75rem',
    padding: theme.spacing(0, 2.5),
    '& > a': {
      minWidth: 'auto',
      height: 'fit-content',
      ...theme.typography.body2,
      fontSize: '0.75rem',
    },
  },
  actionsSecondary: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  actionSecondary: {
    color: theme.palette.secondary.main,
    ...theme.typography.body2,

    padding: theme.spacing(2),
    '&:hover': {
      backgroundColor: 'transparent ',
      textDecoration: 'underline',
    },
  },
  profileButton: {
    paddingRight: 0,
    '& svg': {
      width: '1.75rem',
      height: '1.75rem',
    },
  },
  profileButtonMobile: {
    padding: theme.spacing(1, 0),
    [theme.breakpoints.up('sm')]: {
      paddingRight: 0,
    },
    '& svg': {
      width: '1.75rem',
      height: '1.75rem',
    },
  },

  toolbar: {
    width: '100%',
    maxWidth: '100%',
    minHeight: 'auto',
    padding: '0 2rem',
    background: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '2rem',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
    },
  },
  primaryWrapper: {
    width: '100%',
    maxWidth: '100%',
    minHeight: 'auto',
    padding: '0 2rem',
    backgroundColor: '#12121214',
    color: '#121212',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  actions: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  action: {
    margin: theme.spacing(1.25, 0),
    padding: theme.spacing(0),
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  menuIconButton: {
    pointerEvents: 'all',
    color: theme.palette.common.black,
    padding: theme.spacing(1.45, 0),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 0,
    },
    '&:hover': {
      backgroundColor: 'transparent ',
    },
    '& svg': {
      fontSize: '1.781rem',
    },
  },
  menuIcon: {},
  mobileMenu: {},
});

export default styles;
