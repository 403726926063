// @flow
import type { MessageDescriptor } from 'react-intl';

const prefix = 'global';

export default {
  readMore: ({
    id: `${prefix}.readMore`,
    defaultMessage: 'Read more',
    description: 'Read more link label',
  }: MessageDescriptor),
  closeIcon: ({
    id: `${prefix}.closeIcon`,
    defaultMessage: 'Close',
    description: 'Close icon label',
  }: MessageDescriptor),
  productsDrawerTitle: ({
    id: `${prefix}.productsDrawerTitle`,
    defaultMessage: 'Products in this photo',
    description: 'Products drawer title',
  }: MessageDescriptor),
  buyButton: ({
    id: `${prefix}.buyButton`,
    defaultMessage: 'Buy it',
    description: 'Product buy button label',
  }: MessageDescriptor),
  // TODO: create an specific component/messages field
  newsletterFormInput: ({
    id: `${prefix}.newsletterFormInput`,
    defaultMessage: 'Enter your email address',
    description: 'Newsletter form input label',
  }: MessageDescriptor),
  newsletterFormButton: ({
    id: `${prefix}.newsletterFormButton`,
    defaultMessage: 'Subscribe',
    description: 'Newsletter form button label',
  }: MessageDescriptor),
};
