// @flow
import * as React from 'react';
import withStyles, {
  type StyleRulesCallback,
  type WithStyles,
} from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import Button, {
  type ButtonClassKey,
  type ButtonProps,
} from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import DownArrow from '@material-ui/icons/KeyboardArrowDown';
import classnames from 'classnames';

import FieldLink from '~plugins/prismic/components/FieldLink';
import getMenuType from '~helpers/getMenuType';
import type { PrismicLinkType } from '~schema';

import AppBarMenuLinks from './AppBarMenuLinks';

export type ClassKey = ButtonClassKey | 'iconButton' | 'popper' | 'paper';

export type Props = ButtonProps<
  'button',
  {
    ...$Exact<WithStyles<ClassKey>>,
    className?: string,
    link: ?PrismicLinkType,
    children: ?React.Node,
    menuLink: ?PrismicLinkType,
    MenuProps?: $Diff<
      React.ElementConfig<typeof Menu>,
      {
        open: any,
        anchorEl: any,
        onClose: any,
      },
    >,
  },
>;

export type Styles = StyleRulesCallback<Theme, Props, ClassKey>;

export const styles: Styles = theme => ({
  root: {
    '& .MuiButton-endIcon': {
      marginLeft: 0,
    },
  },
  iconButton: {},
  popper: {
    zIndex: 1,
    maxWidth: 200,
  },
  paper: {
    borderRadius: 0,
    backgroundColor: theme.palette.common.white,
  },
});

const AppBarLink = ({
  link,
  menuLink,
  MenuProps,
  children,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const anchorRef = React.useRef(null);
  const menu = getMenuType(menuLink);

  const [open, setOpen] = React.useState(false);
  const handleOpen = React.useCallback(
    (event: SyntheticEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setOpen(true);
    },
    [],
  );
  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <Button
        ref={anchorRef}
        className={classnames(classes.root, className)}
        {...props}
        component={FieldLink}
        field={link}
        aria-label={children}
        color="primary"
        {...(!!menu
          ? {
              Fallback: 'div',
              endIcon: (
                <IconButton
                  onClick={handleOpen}
                  aria-label="Open dropdown menu"
                  color="inherit"
                  size="small"
                  className={classes.iconButton}
                >
                  <DownArrow color="inherit" />
                </IconButton>
              ),
              onMouseEnter: handleOpen,
              onMouseLeave: open ? handleClose : undefined,
            }
          : null)}
      >
        {children}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
        role={undefined}
        disablePortal
        className={classes.popper}
        {...MenuProps}
      >
        <Paper className={classes.paper} elevation={3}>
          <AppBarMenuLinks menuLinks={menu} onClick={handleClose} />
        </Paper>
      </Popper>
    </>
  );
};

AppBarLink.defaultProps = {
  className: undefined,
};

export default withStyles<*, *, Props>(styles)(AppBarLink);
