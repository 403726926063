// @flow
import * as React from 'react';
import withStyles, {
  type StyleRulesCallback,
  type WithStyles,
} from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import classnames from 'classnames';
import map from 'lodash/map';
import RichText from '~plugins/prismic/components/RichText';
import FieldLink from '~plugins/prismic/components/FieldLink';
import type { PrismicStructuredTextType, PrismicLinkType } from '~schema';

export type ClassKey =
  | 'root'
  | 'title'
  | 'actions'
  | 'description'
  | 'action_section'
  | 'action'
  | 'iconExpand'
  | 'panelButton'
  | 'panel';

export type Props = $ReadOnly<{
  ...$Exact<WithStyles<ClassKey>>,
  className?: string,
  menuTitle: ?PrismicStructuredTextType,
  menuLinks: ?Array<?{
    [string]: any,
    action_label?: ?PrismicStructuredTextType,
    action_link?: ?PrismicLinkType,
  }>,
}>;

export type Styles = StyleRulesCallback<Theme, Props, ClassKey>;

export const styles: Styles = theme => ({
  root: {},
  title: {
    '& > *': {
      fontSize: '0.785rem',
      fontWeight: 700,
      color: theme.palette.text.secondary,
    },
    paddingBottom: theme.spacing(1.5),
  },
  description: {
    '& > *': {
      fontSize: '0.785rem',
      wordBreak: 'break-word',
    },
    '& *:not(:last-child)': { paddingBottom: theme.spacing(2) },
    '& a': {
      color: theme.palette.text.primary,
    },
  },
  panelButton: {
    pointerEvents: 'none',
    minHeight: '0px !important',
    margin: 0,
    padding: 0,
    borderBottom: '1px solid #DEDEDE',

    [theme.breakpoints.down('xs')]: {
      pointerEvents: 'auto',
    },
    '& .MuiExpansionPanelSummary-content ': {
      margin: 0,
    },
  },
  panel: {
    '& .MuiCollapse-container ': {
      height: 'auto !important',
      visibility: 'visible',
    },
    '& .MuiExpansionPanelDetails-root ': {
      padding: 0,
    },
  },
  iconExpand: {
    display: 'none',
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: theme.spacing(1.5),
  },
  action: {
    ...theme.typography.body2,
    fontSize: '0.785rem',
    paddingLeft: theme.spacing(0),
    paddingTop: theme.spacing(0),
    '&:hover': {
      textDecoration: 'underline !important',
      backgroundColor: 'transparent !important',
    },
    '& > .MuiButton-label': {
      justifyContent: 'flex-start',
      textTransform: 'none',
    },
  },
});

const FooterMenu = ({
  menuTitle,
  menuLinks,
  className,
  classes,
  ...props
}: Props): React.Node => {
  return (
    <div className={classnames(classes.root, className)} {...props}>
      <ExpansionPanel className={classes.panel}>
        <ExpansionPanelSummary
          className={classes.panelButton}
          expandIcon={<ExpandMoreIcon className={classes.iconExpand} />}
        >
          <RichText {...menuTitle} className={classes.title} />
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div className={classes.actions}>
            {map(menuLinks, (link, index: number) =>
              link ? (
                <React.Fragment key={index}>
                  {link?.action_description ? (
                    <RichText
                      {...link?.action_description}
                      className={classes.description}
                    />
                  ) : null}
                  {link?.action_link && link?.action_label ? (
                    <Button
                      component={FieldLink}
                      field={link?.action_link}
                      disableTouchRipple
                      aria-label={link?.action_label?.text}
                      className={classes.action}
                    >
                      {link?.action_label?.text}
                    </Button>
                  ) : null}
                  {link?.action_link_section_title ? (
                    <RichText
                      {...link?.action_link_section_title}
                      className={classes.action_section}
                    />
                  ) : null}
                </React.Fragment>
              ) : null,
            )}
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
};

FooterMenu.defaultProps = {
  className: undefined,
};

export default withStyles<*, *, Props>(styles)(FooterMenu);
