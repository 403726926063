// @flow
/* eslint-disable import/no-mutable-exports */
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import createPalette from '@material-ui/core/styles/createPalette';
import responsiveFontSizes from '@material-ui/core/styles/responsiveFontSizes';
import mediaQuery from 'css-mediaquery';

import { INITIAL_BREAKPOINT } from './constants';
import type { ThemeOptions } from './types';
import sanitizePaletteColorOptions from '../../src/plugins/material-ui/helpers/sanitizePaletteColorOptions';

const titleFontFamily = ['ProximaNovaSemibold', 'sans-serif'];
const bodyFontFamily = ['ProximaNovaRegular', 'sans-serif'];

/* GatsbyStarterConfig: material-ui theme */
const palette = createPalette({
  primary: sanitizePaletteColorOptions({
    main: process.env.GATSBY_THEME_PALETTE_PRIMARY_MAIN,
    light: process.env.GATSBY_THEME_PALETTE_PRIMARY_LIGHT,
    dark: process.env.GATSBY_THEME_PALETTE_PRIMARY_DARK,
    contrastText: process.env.GATSBY_THEME_PALETTE_PRIMARY_CONTRAST_TEXT,
  }),
  secondary: sanitizePaletteColorOptions({
    main: process.env.GATSBY_THEME_PALETTE_SECONDARY_MAIN,
    light: process.env.GATSBY_THEME_PALETTE_SECONDARY_LIGHT,
    dark: process.env.GATSBY_THEME_PALETTE_SECONDARY_DARK,
    contrastText: process.env.GATSBY_THEME_PALETTE_SECONDARY_CONTRAST_TEXT,
  }),
  text: {
    primary: '#5F5F5F',
    secondary: '#232323',
  },
  background: {
    paper: '#f3f3f3',
    default: '#FFF',
  },
});

// IMPORTANT: Extra colors defined them below, as `createPalette` will not
//            augment them by default.
palette.tertiary = palette.augmentColor(
  // $FlowFixMe --> Reason: will return a color, as main is always passed
  sanitizePaletteColorOptions({
    main:
      process.env.GATSBY_THEME_PALETTE_TERTIARY_MAIN || palette.secondary.main,
    light: process.env.GATSBY_THEME_PALETTE_TERTIARY_LIGHT,
    dark: process.env.GATSBY_THEME_PALETTE_TERTIARY_DARK,
    contrastText: process.env.GATSBY_THEME_PALETTE_TERTIARY_CONTRAST_TEXT,
  }),
);
palette.quaternary = palette.augmentColor(
  // $FlowFixMe --> Reason: will return a color, as main is always passed
  sanitizePaletteColorOptions({
    main:
      process.env.GATSBY_THEME_PALETTE_QUATERNARY_MAIN ||
      palette.secondary.main,
    light: process.env.GATSBY_THEME_PALETTE_QUATERNARY_LIGHT,
    dark: process.env.GATSBY_THEME_PALETTE_QUATERNARY_DARK,
    contrastText: process.env.GATSBY_THEME_PALETTE_QUATERNARY_CONTRAST_TEXT,
  }),
);

let theme = createMuiTheme(
  ({
    palette,
    // $FlowFixMe --> Reason: font display
    typography: {
      h1: {
        fontFamily: titleFontFamily,
        fontDisplay: 'swap',
        fontSize: '5rem',
      },
      h2: {
        fontFamily: titleFontFamily,
        fontDisplay: 'swap',
      },
      h3: {
        fontFamily: titleFontFamily,
        fontDisplay: 'swap',
        fontSize: '2.875rem',
      },
      h4: {
        fontFamily: titleFontFamily,
        fontDisplay: 'swap',
      },
      h5: {
        fontFamily: bodyFontFamily,
        fontDisplay: 'swap',
      },
      h6: {
        fontFamily: bodyFontFamily,
        fontDisplay: 'swap',
      },
      subtitle1: {
        fontFamily: bodyFontFamily,
        fontDisplay: 'swap',
      },
      subtitle2: {
        fontFamily: bodyFontFamily,
        fontDisplay: 'swap',
      },
      body1: {
        fontFamily: bodyFontFamily,
        fontDisplay: 'swap',
      },
      body2: {
        fontFamily: bodyFontFamily,
        fontDisplay: 'swap',
      },
      caption: {
        fontFamily: bodyFontFamily,
        fontDisplay: 'swap',
        fontSize: '0.875rem',
      },
      button: {
        fontFamily: titleFontFamily,
        fontDisplay: 'swap',
        textTransform: 'capitalize',
      },
      overline: {
        fontFamily: bodyFontFamily,
        textTransform: 'uppercase',
        fontSize: '0.875rem',
      },
    },
    transitions: {
      duration: {
        complex: 500,
      },
    },
    props: {
      MuiWithWidth: { initialWidth: INITIAL_BREAKPOINT },
      MuiUseMediaQuery: {
        ssrMatchMedia:
          typeof window === 'undefined'
            ? (query: string) => ({
                matches: mediaQuery.match(query, {
                  width: theme.breakpoints.values[INITIAL_BREAKPOINT],
                }),
              })
            : undefined,
      },
      MuiButton: { disableElevation: true },
      MuiPaper: { elevation: 0 },
      BodySection: {
        paddingAlterFactor: {
          xs: 1.25,
          md: 1.5,
          lg: 2,
        },
      },
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '.MuiListText-root': {
            margin: 0,
            position: 'relative',
          },
          '.MuiListText-padding': {
            paddingTop: 8,
            paddingBottom: 8,
          },
          '.MuiListText-ordered': {},
          '.MuiPreformatted-root': {
            maxWidth: '100%',
            overflowX: 'auto',
            fontSize: '1rem',
          },
        },
      },
      MuiFormControl: {
        root: {
          '&[hidden]': {
            display: 'none',
          },
        },
      },
      MuiButton: {
        root: {
          borderRadius: 0,
        },
        contained: {
          backgroundColor: '#FFF',
          '&:hover': {
            backgroundColor: palette.secondary.main,
            color: palette.secondary.contrastText,
            boxShadow: `inset 0px 0px 0px 1px ${palette.secondary.contrastText} !important`,
          },
        },
        containedSecondary: {
          '&:hover': {
            backgroundColor: palette.secondary.contrastText,
            color: palette.secondary.main,
            boxShadow: `inset 0px 0px 0px 1px ${palette.secondary.main} !important`,
          },
        },
      },
      MuiPaper: {
        rounded: {
          borderRadius: 0,
        },
      },
      MuiDrawer: {
        paper: {
          boxShadow: 'none',
        },
      },
    },
  }: ThemeOptions),
);

/* GatsbyStarterConfig: responsive font sizes */
theme = responsiveFontSizes(theme, {
  breakpoints: undefined,
  disableAlign: undefined,
  factor: undefined,
  variants: undefined,
});

export default theme;
