// @flow
import * as React from 'react';
import MaterialUILayout from 'material-ui-layout';
import withStyles, {
  type StyleRulesCallback,
  type WithStyles,
} from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';

import AppBar from '~components/AppBar';
import Footer from '~components/Footer';
import Drawer from '~components/Drawer';
import CookieConsent from '~plugins/prismic-cookies/components/CookieConsent';
import usePageContext from '~plugins/page-context/hooks/usePageContext';

export type ClassKey =
  | '@global'
  | 'layout'
  | 'layoutMain'
  | 'layoutAppBar'
  | 'layoutDrawer'
  | 'layoutFooter';

export type Props = $ReadOnly<{
  ...$Exact<WithStyles<ClassKey>>,
  children: React.Node,
}>;

export type Styles = StyleRulesCallback<Theme, Props, ClassKey>;

const styles: Styles = theme => ({
  '@global': {
    '::-webkit-scrollbar': {
      width: 5,
    },
    '::-webkit-scrollbar-track': {
      background: 'none',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#ccc',
    },
    '.MuiDrawer-root': {
      top: '89px !important',
      [theme.breakpoints.down('sm')]: {
        top: '51px !important',
      },
    },
    '.MuiBackdrop-root': {
      top: 89,
      [theme.breakpoints.down('sm')]: {
        top: 51,
      },
    },
  },
  layout: {},
  layoutMain: {},
  layoutAppBar: {
    boxShadow: 'none',
  },
  layoutDrawer: {
    width: '100%',
    height: 'calc(100% - 89px)',
    maxWidth: 400,
    top: 89,
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100% - 51px)',
      top: 51,
    },
  },
  layoutFooter: {
    padding: theme.spacing(0),
  },
});

const BaseLayout = ({ children, classes }: Props) => {
  const { secondaryAppBar } = usePageContext();

  return (
    <>
      <MaterialUILayout
        mainGrow={false}
        stickyFooter
        appBarPosition="relative"
        appBarContent={<AppBar secondaryAppBarData={secondaryAppBar} />}
        footerContent={<Footer />}
        leftDrawerContent={
          <Drawer position="left" secondaryAppBarData={secondaryAppBar} />
        }
        classes={React.useMemo(
          () => ({
            layout: classes.layout,
            main: classes.layoutMain,
            appBar: classes.layoutAppBar,
            drawerPaper: classes.layoutDrawer,
            footer: classes.layoutFooter,
          }),
          [classes],
        )}
      >
        {children}
      </MaterialUILayout>
      <CookieConsent showReadMore />
    </>
  );
};

export default withStyles<*, *, Props>(styles)(BaseLayout);
