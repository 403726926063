// @flow
import * as React from 'react';
import withStyles, {
  type StyleRulesCallback,
  type WithStyles,
} from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button, { type ButtonProps } from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import classnames from 'classnames';

import Link from '~plugins/i18n/components/Link';
import getMenuType from '~helpers/getMenuType';
import type { PrismicLinkType } from '~schema';

import DrawerMenuLinks from './DrawerMenuLinks';

export type ClassKey =
  | 'root'
  | 'item'
  | 'text'
  | 'expandedBase'
  | 'expandedBaseItem'
  | 'expandedBaseDetails';

export type Props = {
  ...$Exact<ButtonProps<'button', {}>>,
  ...$Exact<WithStyles<ClassKey>>,
  label: string,
  menuLink?: ?PrismicLinkType,
  onClick: () => mixed,
};

export type Styles = StyleRulesCallback<Theme, Props, ClassKey>;

export const styles: Styles = theme => ({
  root: {
    padding: theme.spacing(1.25, 4),
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  item: {
    padding: 0,
    '& .MuiListItemText-root': {
      margin: 0,
    },
  },
  text: {
    color: theme.palette.primary.main,
  },
  expandedBase: {
    margin: '0px !important',
    background: theme.palette.common.white,
    // borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    '& .MuiExpansionPanelSummary-root	': {
      padding: 0,
      paddingRight: theme.spacing(2),
      minHeight: 'auto',
    },
    '& .MuiExpansionPanelSummary-content': {
      margin: 0,
    },
    '&::before': {
      display: 'none !important',
    },
  },
  expandedBaseItem: {},
  expandedBaseDetails: {
    padding: 0,
  },
});

const DrawerItem = ({
  label,
  onClick,
  menuLink,
  classes,
  ...props
}: Props): React.Node => {
  const menu = getMenuType(menuLink);
  const ExpansionPanelContainer = ({ wrapper, children }) =>
    menu ? wrapper(children) : children;

  return (
    <ExpansionPanelContainer
      wrapper={children => (
        <ExpansionPanel elevation={0} className={classes.expandedBase}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            {children}
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.expandedBaseDetails}>
            <DrawerMenuLinks menuLinks={menu} onClick={onClick} />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}
    >
      <Button
        component={Link}
        onClick={onClick}
        disableRipple
        classes={{ root: classes.root }}
        {...(menu ? { Fallback: 'div' } : null)}
        {...props}
      >
        <ListItem
          aria-label={label}
          className={classnames(classes.item, {
            [classes.expandedBaseItem]: menu,
          })}
        >
          <ListItemText
            primary={label}
            primaryTypographyProps={{
              variant: 'body2',
              className: classes.text,
            }}
          />
        </ListItem>
      </Button>
    </ExpansionPanelContainer>
  );
};

DrawerItem.defaultProps = {
  menuLink: undefined,
};

export default withStyles<*, *, Props>(styles)(DrawerItem);
