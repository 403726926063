// @flow
import type { Styles } from './types';

const styles: Styles = theme => ({
  root: {
    background: theme.palette.background.paper,
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  copyright: {
    textAlign: 'center',
    paddingTop: '0px !important',
    '& > *': {
      fontSize: '0.688rem',
    },
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
    },
  },
  link: {
    color: theme.palette.primary.main,
  },
  paymentsImage: {
    maxWidth: '100%',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 380,
    },
  },
  separator: {
    position: 'absolute',
    left: 0,
    width: '100%',
    height: '1px',
    background: '#ebebeb',
  },
  socialLinks: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  socialLink: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(0),
    marginRight: theme.spacing(1),
  },
  form: {
    display: 'flex',
    maxWidth: 358,
    marginLeft: 'auto',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      margin: '0 auto',
    },
  },
  formWrapper: {
    margin: 'auto',
  },
  menuWrapper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    marginLeft: 0,
    marginRight: 0,
    padding: `${theme.spacing(0)}!important`,
  },
  textField: {
    '& > div': {
      borderRadius: 0,
      paddingRight: 0,
    },
    '& .MuiOutlinedInput-input': {
      padding: '15px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#777777',
    },
  },
  button: {
    padding: '6px 24px',
    color: theme.palette.secondary.main,
    '&:hover': {
      boxShadow: 'none !important',
      backgroundColor: 'transparent',
    },
    '& svg': {
      width: 15,
    },
  },
  bottomMenuWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    gap: '1rem',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
});

export default styles;
