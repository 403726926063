// @flow
import * as React from 'react';
import SvgIcon, { type SvgIconProps } from '@material-ui/core/SvgIcon';

export type Props = SvgIconProps;

const MenuIcon = ({ ...props }: Props): React.Node => {
  return (
    <SvgIcon
      width="48px"
      height="48px"
      viewBox="0 0 48 48"
      fill="currentColor"
      {...props}
    >
      <path d="M6 36v-3h36v3Zm0-10.5v-3h36v3ZM6 15v-3h36v3Z" />
    </SvgIcon>
  );
};

export default React.memo<Props>(MenuIcon);
