// @flow
/* eslint-disable spaced-comment */

module.exports = function getEnvVar(varName /*: string */) {
  const { ENV_VAR_NAMESPACE } = process.env;
  return (
    (varName && typeof ENV_VAR_NAMESPACE === 'string'
      ? process.env[`${ENV_VAR_NAMESPACE}_${varName}`]
      : undefined) || process.env[varName]
  );
};
