// @flow
import * as React from 'react';
import withStyles, {
  type StyleRulesCallback,
  type WithStyles,
} from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import classnames from 'classnames';
import map from 'lodash/map';

import FieldLink from '~plugins/prismic/components/FieldLink';
import type { PrismicMenu } from '~schema';

export type ClassKey = 'root' | 'item' | 'link';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  className?: string,
  menuLinks: ?PrismicMenu,
  onClick?: (event: Event) => void,
};

export type Styles = StyleRulesCallback<Theme, Props, ClassKey>;

export const styles: Styles = theme => ({
  root: {},
  item: {
    '& .MuiButton-root': {
      padding: '10px 16px',
    },
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
      ...theme.typography.body1,
    },
  },
  link: {
    width: '100%',
    textTransform: 'capitalize',
    '&:hover': {
      background: 'transparent',
      textShadow: '0px 0px 1px black',
    },
  },
});

const AppBarMenuLinks = ({
  menuLinks,
  onClick,
  className,
  classes,
  ...props
}: Props): React.Node => {
  return (
    <Grid container className={classnames(classes.root, className)} {...props}>
      {map(menuLinks?.data?.menu_actions, (action, index: number) => {
        return (
          <Grid key={index} item xs={12} className={classes.item}>
            <Button
              component={FieldLink}
              field={action?.action_link}
              onClick={onClick}
              aria-label={action?.action_label?.text}
              disableRipple
              className={classes.link}
            >
              {action?.action_label?.text}
            </Button>
          </Grid>
        );
      })}
    </Grid>
  );
};

AppBarMenuLinks.defaultProps = {
  className: undefined,
  onClick: undefined,
};

export default withStyles<*, *, Props>(styles)(AppBarMenuLinks);
