// @flow
import * as React from 'react';
import withStyles, {
  type WithStyles,
} from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';

import Link from '~plugins/i18n/components/Link';
import usePageTranslations from '~plugins/i18n/hooks/usePageTranslations';
import useCurrentLocale from '~plugins/i18n/hooks/useCurrentLocale';

export type ClassKey = 'root' | 'label' | 'formSelect' | 'formSelectItem';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  className?: string,
};

const styles = (theme: Theme) => ({
  root: {
    minWidth: 115,
  },
  label: {
    transform: 'translate(14px, 12px) scale(1)',
    '&.MuiInputLabel-shrink': {
      transform: 'translate(14px, -8px) scale(0.75);',
    },
  },
  formSelect: {
    borderRadius: 0,
    '& .MuiOutlinedInput-input': {
      padding: '14px',
    },
    '& .MuiSelect-root': {
      fontSize: theme.typography.caption.fontSize,
    },
  },
  formSelectItem: {},
});

const SelectorLanguagePicker = ({ classes, className, ...props }: Props) => {
  const [language, setLanguage] = React.useState('');

  const handleChange = event => {
    if (event.target instanceof HTMLSelectElement) {
      setLanguage(event.target.value);
    }
  };

  const currentLocale = useCurrentLocale();
  const pageTranslations = usePageTranslations().filter(
    pageTranslation => pageTranslation.exist || pageTranslation.current,
  );

  return (
    <FormControl
      variant="outlined"
      className={classnames(className, classes.root)}
      {...props}
    >
      <InputLabel id={currentLocale.nativeName} className={classes.label}>
        <Typography variant="caption">Language</Typography>
      </InputLabel>
      <Select
        labelId={currentLocale.nativeName}
        value={language}
        onChange={handleChange}
        label="Language"
        displayEmpty
        className={classes.formSelect}
      >
        {pageTranslations.map(({ path, locale, current, exist }) => {
          const disabled = !(current || exist);
          return (
            <MenuItem
              key={locale.id}
              to={path}
              component={Link}
              disabled={disabled}
              localized
              value={locale.nativeName}
              aria-label={locale.nativeName}
              className={classes.formSelectItem}
            >
              {locale.nativeName}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

SelectorLanguagePicker.defaultProps = {
  className: undefined,
};

export default withStyles<ClassKey, *, Props>(styles)(SelectorLanguagePicker);
