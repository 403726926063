// @flow
import type { PrismicStructuredTextType } from '~schema';

export type Config = {|
  operator: 'and' | 'or',
|};

export default function hasRichTextFieldValue(
  richTextField?: ?PrismicStructuredTextType,
  config?: Config,
) {
  const { operator = 'and' } = config || {};

  const hasTextValue = !!(
    richTextField &&
    typeof richTextField.text === 'string' &&
    richTextField.text !== ''
  );
  const hasHtmlValue = !!(
    richTextField &&
    typeof richTextField.html === 'string' &&
    richTextField.html !== '<p></p>'
  );

  return !!{
    and: hasTextValue && hasHtmlValue,
    or: hasTextValue || hasHtmlValue,
  }[operator];
}
