// @flow
import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import SvgIcon from '@material-ui/core/SvgIcon';
import classnames from 'classnames';

import type { Props } from './types';
import styles from './styles';

const SiteLogo = ({ className, classes, ...props }: Props): React.Node => (
  <SvgIcon
    width="402px"
    height="52px"
    viewBox="0 0 402 52"
    className={classnames(classes.root, className)}
    {...props}
  >
    <path
      fill="#111"
      d="M396.015 5.02h.405c.575 0 .932-.023 1.071-.067a.629.629 0 0 0 .332-.975.653.653 0 0 0-.335-.23c-.145-.045-.501-.069-1.069-.069h-.404v1.342Zm-.996 2.79-.001-4.91h.997c.933 0 1.457.005 1.569.013.331.028.587.092.771.194.183.104.336.26.459.471.122.212.184.447.184.705 0 .354-.116.658-.347.908-.232.252-.556.408-.975.468.15.058.264.118.344.186.08.066.192.192.332.376.036.05.158.25.363.598l.58.99-1.232.001-.411-.797c-.277-.535-.508-.872-.689-1.01-.18-.14-.411-.21-.688-.21h-.26l.001 2.017h-.997Zm2.095-6.196c-.638 0-1.247.16-1.831.477a3.47 3.47 0 0 0-1.379 1.371 3.766 3.766 0 0 0-.504 1.872c0 .65.164 1.268.492 1.855a3.508 3.508 0 0 0 1.375 1.376c.588.33 1.204.494 1.848.494.648 0 1.265-.165 1.852-.496a3.536 3.536 0 0 0 1.373-1.376c.33-.588.495-1.205.495-1.854a3.76 3.76 0 0 0-.505-1.872 3.47 3.47 0 0 0-1.384-1.37 3.8 3.8 0 0 0-1.832-.477Zm-.007-.905c.793 0 1.553.197 2.283.593a4.292 4.292 0 0 1 1.718 1.707c.418.74.626 1.514.626 2.324 0 .805-.204 1.573-.612 2.304-.408.73-.978 1.3-1.708 1.71a4.648 4.648 0 0 1-2.304.612 4.627 4.627 0 0 1-2.304-.612 4.35 4.35 0 0 1-1.71-1.708 4.65 4.65 0 0 1-.614-2.304c0-.809.208-1.584.625-2.325.416-.74.988-1.31 1.717-1.707a4.717 4.717 0 0 1 2.283-.594ZM44.643.736l14.65-.008-5.422 13.21-6.542.002 4.588 4.86c-2.052 5.38-11.293 27.14-13.402 32.621l-26.5.01L.28 41.197C5.667 27.473 11.16 14.503 16.403.744L30.829.74l-5.078 13.207-7.022.002 5.152 4.86c-2.153 5.622-4.79 11.174-6.836 16.587-.396.93-.744 1.896-1.101 2.828l7.961 8.41L44.643.737ZM115.937 13.92l-6.476.002 4.582 4.86c-2.209 5.552-4.352 11.036-6.486 16.38-.851 2.174-1.702 4.346-2.551 6.519l17.28.004 10.327 9.72-31.412-.002-10.367-9.718c2.268-5.794 4.532-11.622 6.825-17.518 3.062-7.829 6.183-15.622 9.268-23.449l14.26-.004-5.25 13.207Z"
    />
    <path
      fill="#111"
      d="m97.332 13.926-30.041.01 4.798 4.86 12.154-.005L78.6 32.275l-16.407.004 8.827 9.225 19.976-.005-4.733 9.772-.04.137-29.04.008-8.705-9.49 1.529-3.712L65.276.73l37.535-.01-5.48 13.206ZM379.521 13.88h-.823l4.455 4.86c-2.243 5.586-4.468 11.07-6.636 16.449-2.135 5.345-4.291 10.724-6.427 16.172l-10.176-9.72 11.053-27.759-6.498.002a789.908 789.908 0 0 1-4.302 10.725c-2.12 5.379-8.157 21.275-10.304 26.759l-10.206-9.722 10.694-27.757-5.416.001-6.424.002 4.484 4.86a14059.98 14059.98 0 0 1-6.508 16.414c-2.1 5.346-4.214 10.759-6.356 16.207l-9.993-9.985c3.889-9.99 14.723-35.598 16.757-40.7l13.239-.004h.076l4.079-.002h1.348l11.228-.004 2.918-.001h3.292l3.22-.001 7.448-.003 6.735-.001-5.356 13.206-5.565.002h-.036ZM157.408 38.182l-12.077.004 8.16-19.448-4.698-4.826 18.862-.007-10.247 24.277ZM174.539.698l-28.432.008-5.596 13.208L130.4 38.19l-1.475 3.483 10.287 9.72 29.016-.008L184.704 9.63 174.539.698Z"
    />
    <path
      fill="#111"
      d="M216.366 13.962c-3.287 0-6.619-.034-9.907-.032l-9.822.002 4.582 4.86c-2.093 5.587-7.566 18.484-7.938 19.486 2.158-.002 4.94-.038 7.062-.038 2.112-.036 4.27-.036 6.39-.037l9.642-24.207-.009-.034Zm15.829-3.523-12.581 32.57-9.683 8.398-36.526.012c2.71-6.87 14.113-34.199 17.285-42.104L180.859.732 222.973.72l9.222 9.72Z"
    />
    <path
      fill="#111"
      d="m301.25 38.176-11.982.004 7.956-19.448-4.694-4.827 18.712-.006-9.992 24.277Zm-57.038-24.325 22.224-.007-20.306 11.867 2.698-7.035-4.616-4.825ZM289.771.7l-5.459 13.208-9.853 24.276-2.389 5.61-13.358-13.26 3.976-2.277h-.038l13.334-7.693 2.739-6.724L284.175.632l-41.579.012h-.698l-.538.001-11.226.003h-1.524l9.073 9.605A13470.41 13470.41 0 0 0 233.3 21.75c-2.602 6.896-5.12 13.518-7.512 19.863l.975.896 9.393 8.824 8.071-20.621 20.488 20.676h45.903l5.442-13.215L331.611.688 289.771.7Z"
    />
  </SvgIcon>
);

SiteLogo.defaultProps = {
  className: undefined,
};

export default withStyles<*, *, Props>(styles)(SiteLogo);
