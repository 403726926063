// @flow
import * as React from 'react';
import withStyles, {
  type StyleRulesCallback,
  type WithStyles,
} from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import IconButton from '@material-ui/core/IconButton';
import classnames from 'classnames';
import map from 'lodash/map';

import FacebookIcon from '~components/icons/FacebookIcon';
import FacebookAltIcon from '~components/icons/FacebookAltIcon';
import YoutubeIcon from '~components/icons/YoutubeIcon';
import YoutubeAltIcon from '~components/icons/YoutubeAltIcon';
import InstagramIcon from '~components/icons/InstagramIcon';
import InstagramAltIcon from '~components/icons/InstagramAltIcon';
import FieldLink from '~plugins/prismic/components/FieldLink';
import type { PrismicStructuredTextType, PrismicLinkType } from '~schema';

export type ClassKey = 'root' | 'iconButton' | 'icon';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  className?: string,
  links: ?Array<?{
    [any]: any,
    social_label?: ?PrismicStructuredTextType,
    social_link?: ?PrismicLinkType,
    social_icon?: ?string,
  }>,
  displayAlternateIcons?: boolean,
};

export type Styles = StyleRulesCallback<Theme, Props, ClassKey>;

export const styles: Styles = unusedTheme => ({
  root: {
    width: 'auto',
  },
  iconButton: {},
  icon: {
    width: 36,
    height: 36,
  },
});

const icons: { [string]: React.ComponentType<any> } = {
  Facebook: FacebookIcon,
  Instagram: InstagramIcon,
  Youtube: YoutubeIcon,
};

const iconsAlt: { [string]: React.ComponentType<any> } = {
  Facebook: FacebookAltIcon,
  Instagram: InstagramAltIcon,
  Youtube: YoutubeAltIcon,
};

const SocialIcon = ({
  icon,
  alternateIcons,
  ...props
}: {
  [any]: any,
  icon: string,
  alternateIcons: boolean,
}) => {
  const Component = alternateIcons ? iconsAlt[icon] : icons[icon];
  return Component ? <Component {...props} /> : null;
};

const SocialLinks = ({
  className,
  classes,
  links,
  displayAlternateIcons = false,
  ...props
}: Props): React.Node => {
  return (
    <div className={classnames(classes.root, className)} {...props}>
      {map(links, (link, index: number) => {
        const getSocialIcon = displayAlternateIcons ? iconsAlt : icons;
        return link &&
          link.social_label &&
          link.social_icon &&
          getSocialIcon[link.social_icon] ? (
          <IconButton
            key={index}
            component={FieldLink}
            field={link?.social_link}
            aria-label={link?.social_label?.text || link.social_icon}
            className={classes.iconButton}
          >
            <SocialIcon
              icon={link.social_icon}
              alternateIcons={displayAlternateIcons}
              className={classes.icon}
            />
          </IconButton>
        ) : null;
      })}
    </div>
  );
};

SocialLinks.defaultProps = {
  className: undefined,
  displayAlternateIcons: undefined,
};

export default withStyles<*, *, Props>(styles)(SocialLinks);
