// @flow
import * as React from 'react';
import { type IntlShape } from 'react-intl';
import classnames from 'classnames';
import compose from 'recompose/compose';
import withPropsOnChange from 'recompose/withPropsOnChange';
import mapProps from 'recompose/mapProps';
import type { HOC } from 'recompose';
import withStyles, {
  type WithStyles,
} from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import omit from 'lodash/fp/omit';
import uniq from 'lodash/uniq';

import GatsbyLink from '~components/GatsbyLink';

import useLocalizePath from '../hooks/useLocalizePath';
import withPageContext from '../../page-context/hocs/withPageContext';

export const styles = (theme: Theme) => ({
  root: {
    textDecoration: 'none',
  },
  colorInherit: {
    color: 'inherit',
  },
  colorPrimary: {
    color: theme.palette.primary.main,
  },
  colorSecondary: {
    color: theme.palette.secondary.main,
  },
});

export type ClassKey =
  | 'root'
  | 'colorInherit'
  | 'colorPrimary'
  | 'colorSecondary';

export type Color = $NonMaybeType<
  $ElementType<React.ElementConfig<typeof Button>, 'color'>,
>;

export type Props = {
  ...$Exact<React.ElementConfig<typeof GatsbyLink>>,
  ...$Exact<WithStyles<ClassKey>>,
  target?: '_blank' | '_self' | '_parent' | '_top' | string,
  rel?: string,
  children: React.Node,
  intl: IntlShape,
  localized?: boolean,
  className?: string,
  classes: { [ClassKey]: string },
  color?: Color,
  forwardRef?: React.Ref<any>,
};

const Link = ({
  to,
  intl,
  localized,
  classes,
  className,
  color,
  forwardRef,
  rel,
  target,
  ...props
}: Props) => {
  const localizePath = useLocalizePath();
  const isGatsbyLink = target !== '_blank';
  const Component = isGatsbyLink ? GatsbyLink : 'a';
  const href = localized ? to : localizePath(to);

  return (
    <Component
      {...props}
      rel={React.useMemo(
        () =>
          target === '_blank'
            ? uniq([
                ...(rel || '').split(/\s+/g),
                'nooopener',
                'noreferrer',
              ]).join(' ')
            : rel,
        [target, rel],
      )}
      target={target}
      ref={forwardRef}
      className={classnames(className, classes.root, {
        [classes.colorInherit]: color === 'inherit',
        [classes.colorPrimary]: color === 'primary',
        [classes.colorSecondary]: color === 'secondary',
      })}
      {...(isGatsbyLink ? { to: href } : { href })}
    />
  );
};

Link.defaultProps = {
  className: undefined,
  localized: false,
  color: undefined,
  forwardRef: undefined,
  target: undefined,
  rel: undefined,
};

const enhancer: HOC<
  Props,
  {
    ...$Diff<Props, { classes: any, to: any }>,
    classes?: $ElementType<Props, 'classes'>,
    to: $NonMaybeType<$ElementType<Props, 'to'>>,
  },
> = compose(
  withPageContext(),
  withPropsOnChange(['to', 'pageContext'], ({ to, pageContext, localized }) => {
    const prefixCurrentPath = to && /^[#?&]/.test(to);
    return {
      // IMPORTANT: make sure to unset the localized flag when we are prefixing
      // the current path
      localized: !prefixCurrentPath && localized,
      to: prefixCurrentPath ? `${pageContext.originalPath}${to}` : to,
    };
  }),
  mapProps(omit(['pageContext'])),
  withStyles<ClassKey, *, Props>(styles),
);

const EnhancedLink = enhancer(Link);

// eslint-disable-next-line react/display-name
export default React.forwardRef<React.ElementConfig<typeof EnhancedLink>, *>(
  (props, ref) => <EnhancedLink forwardRef={ref} {...props} />,
);
