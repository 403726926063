// @flow
import type { PrismicLinkType, PrismicMenu } from '~schema';

export default function getMenuType(
  link: ?PrismicLinkType,
): PrismicMenu | void {
  const document = link && link.document;
  return document &&
    (document.__typename === 'PrismicMenu' || document.type === 'menu')
    ? // $FlowFixMe --> Reason: not infering type from `document.type`
      document
    : undefined;
}
