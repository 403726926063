// @flow
import * as React from 'react';
import { useState, useEffect } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

import Toolbar from '@material-ui/core/Toolbar';
import ButtonBase from '@material-ui/core/ButtonBase';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';

import classnames from 'classnames';
import map from 'lodash/map';

import SiteLogo from '~components/SiteLogo';
import ProfileButton from '~components/icons/ProfileButton';
import MenuIcon from '~components/icons/MenuIcon';
import Link from '~plugins/i18n/components/Link';
import useSiteInfo from '~hooks/useSiteInfo';
import FieldLink from '~plugins/prismic/components/FieldLink';

import type { Props } from './types';
import styles from './styles';
import AppBarLink from './AppBarLink';

const AppBar = ({
  secondaryAppBarData,
  toggleRightDrawer,
  toggleLeftDrawer,
  className,
  classes,
  ...props
}: Props): React.Node => {
  // eslint-disable-next-line no-use-before-define
  const scrollDirection = useScrollDirection();

  const handleIconClick = toggleLeftDrawer;
  const siteInfo = useSiteInfo();

  return (
    <div
      className={classnames(classes.root, className)}
      style={{ top: scrollDirection === 'down' ? '-130px' : 0 }}
    >
      <div className={classes.primaryWrapper}>
        <div className={classes.wrapper}>
          <div className={classes.actionsRight}>
            <div className={classes.actionsRightWrapper}>
              {map(
                siteInfo?.data?.appbar_primary_actions,
                (action, index: number) =>
                  action && action.action_label && action.action_label.text ? (
                    <Button
                      key={index}
                      component={FieldLink}
                      field={action.action_link}
                      color="inherit"
                      aria-label={action.action_label?.text}
                      className={classes.action}
                    >
                      {action.action_label?.text}
                    </Button>
                  ) : null,
              )}
            </div>
            <ButtonBase
              component={Link}
              to="https://www.velodrom.cc/account/"
              aria-label="Velodrom account"
              localized
              className={classes.profileButton}
            >
              <ProfileButton className={classes.profile} />
            </ButtonBase>
          </div>
        </div>
      </div>
      <Toolbar
        component={Container}
        className={classnames(classes.toolbar, className)}
        {...props}
      >
        <Hidden mdUp implementation="css">
          <IconButton
            disableRipple
            aria-label="Menu Icon"
            onClick={handleIconClick}
            className={classes.menuIconButton}
          >
            <MenuIcon className={classes.menuIcon} />
          </IconButton>
        </Hidden>
        <ButtonBase
          component={Link}
          to="https://www.velodrom.cc/"
          aria-label="Velodrom home page"
          localized
          className={classes.siteLogoButton}
        >
          <SiteLogo className={classes.siteLogo} />
        </ButtonBase>
        <Hidden mdUp implementation="css">
          <ButtonBase
            component={Link}
            to="https://www.velodrom.cc/account/"
            aria-label="Velodrom account"
            localized
            className={classes.profileButtonMobile}
          >
            <ProfileButton className={classes.profile} />
          </ButtonBase>
        </Hidden>
        <div className={classes.actionsSecondary}>
          {secondaryAppBarData
            ? map(
                secondaryAppBarData?.data?.appbar_actions,
                (action, index: number) =>
                  action && action.action_label && action.action_label.text ? (
                    <AppBarLink
                      key={index}
                      link={action.action_link}
                      menuLink={action.action_menu_relation}
                      className={classes.actionSecondary}
                    >
                      {action.action_label?.text}
                    </AppBarLink>
                  ) : null,
              )
            : map(
                siteInfo?.data?.appbar_secondary_actions,
                (action, index: number) =>
                  action && action.action_label && action.action_label.text ? (
                    <AppBarLink
                      key={index}
                      link={action.action_link}
                      menuLink={action.action_menu_relation}
                      className={classes.actionSecondary}
                    >
                      {action.action_label?.text}
                    </AppBarLink>
                  ) : null,
              )}
        </div>
      </Toolbar>
    </div>
  );
};

AppBar.defaultProps = {
  className: undefined,
};

export default withStyles<*, *, Props>(styles)(AppBar);

function useScrollDirection() {
  const [scrollDirection, setScrollDirection] = useState(null);

  useEffect(() => {
    let lastScrollY = window.pageYOffset;

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;
      const direction = scrollY > lastScrollY ? 'down' : 'up';
      if (
        direction !== scrollDirection &&
        (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)
      ) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };
    window.addEventListener('scroll', updateScrollDirection);
    return () => {
      window.removeEventListener('scroll', updateScrollDirection);
    };
  }, [scrollDirection]);

  return scrollDirection;
}
