// @flow
import * as React from 'react';
import type { StyledComponentProps } from '@material-ui/core/styles/withStyles';

import { SNACKBAR_DEFAULT_AUTO_HIDE_DURATION } from '../../../../../../config/material-ui/constants';

import type { State, Action } from '../types';
import * as SnackbarTypes from './Snackbar/types';
import SnackbarContext from '../config/context';
import reducer, { initialState } from '../config/reducer';
import * as actions from '../config/actions';

export type Props = {|
  Snackbar: React.ComponentType<{
    ...$Exact<$Diff<SnackbarTypes.Props, { classes: any }>>,
    ...$Exact<StyledComponentProps<SnackbarTypes.ClassKey>>,
  }>,
  children: React.Node,
|};

const SnackbarProvider = ({ Snackbar, children }: Props) => {
  const [state, dispatch] = React.useReducer<State, Action>(
    reducer,
    initialState,
  );

  const show = React.useCallback(
    message =>
      dispatch({
        type: actions.SHOW_MESSAGE,
        payload: message,
      }),
    [],
  );

  const message = state.messages.length > 0 ? state.messages[0] : undefined;

  return (
    <SnackbarContext.Provider value={React.useMemo(() => ({ show }), [])}>
      {children}
      <Snackbar
        open={state.open}
        autoHideDuration={
          message?.autoHideDuration || SNACKBAR_DEFAULT_AUTO_HIDE_DURATION
        }
        message=""
        {...message}
        onClose={React.useCallback((unusedEvent, reason) => {
          if (reason === 'clickaway') {
            return;
          }
          dispatch({ type: actions.CLOSE });
        }, [])}
        onExited={React.useCallback(() => {
          dispatch({ type: actions.SHOW_NEXT_MESSAGE });
        }, [])}
      />
    </SnackbarContext.Provider>
  );
};

export default SnackbarProvider;
