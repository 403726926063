// @flow
import { graphql, useStaticQuery } from 'gatsby';

import useFindCurrentLocaleNode from '~plugins/prismic/hooks/useFindCurrentLocaleNode';
import type { Query, PrismicSiteInfo } from '~schema';

const query = graphql`
  query UseSiteInfoQuery {
    allPrismicSiteInfo {
      nodes {
        id
        lang
        data {
          appbar_primary_actions {
            action_label {
              text
            }
            action_link {
              type
              link_type
              url
            }
            action_link_section_title {
              text
            }
            action_menu_relation {
              type
              link_type
              url
            }
          }
          appbar_secondary_actions {
            action_label {
              text
            }
            action_link {
              type
              link_type
              url
            }
            action_link_section_title {
              text
            }
            action_menu_relation {
              type
              link_type
              url
              document {
                ... on PrismicMenu {
                  id
                  data {
                    menu_actions {
                      action_label {
                        text
                        html
                      }
                      action_link {
                        type
                        link_type
                        url
                      }
                    }
                  }
                }
              }
            }
          }
          footer_copyright {
            html
            text
          }
          footer_actions {
            action_menu_relation {
              type
              link_type
              url
              document {
                ... on PrismicMenu {
                  data {
                    name {
                      html
                      text
                      raw
                    }
                    menu_actions {
                      action_description {
                        html
                        text
                        raw
                      }
                      action_label {
                        html
                        text
                        raw
                      }
                      action_link {
                        type
                        link_type
                        url
                      }
                      action_link_section_title {
                        html
                        text
                        raw
                      }
                    }
                  }
                }
              }
            }
          }
          social_links {
            social_label {
              html
              text
            }
            social_link {
              link_type
              target
              url
              isBroken
            }
            social_icon
          }
        }
      }
    }
  }
`;

export default function useSiteInfo(): ?PrismicSiteInfo {
  return useFindCurrentLocaleNode<PrismicSiteInfo>({
    nodes: useStaticQuery<Query>(query).allPrismicSiteInfo?.nodes,
    toQuery: node => ({ prismicSiteInfo: node }),
    fromQuery: data => data?.prismicSiteInfo,
  });
}
