// @flow
import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { LayoutActions } from 'material-ui-layout';
import classnames from 'classnames';
import map from 'lodash/map';

import useSiteInfo from '~hooks/useSiteInfo';
import FieldLink from '~plugins/prismic/components/FieldLink';
import SocialLinks from '../SocialLinks';

import type { Props } from './types';
import styles from './styles';
import DrawerItem from './DrawerItem';
import SelectorLanguagePicker from '../i18n/SelectorLanguagePicker';

const Drawer = ({
  secondaryAppBarData,
  closeDrawer,
  closeDrawerOnClick,
  position,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const siteInfo = useSiteInfo();
  const layoutActions = React.useContext(LayoutActions);

  const handleDrawerClose =
    position === 'right'
      ? layoutActions.handleRightDrawerClose
      : layoutActions.handleLeftDrawerClose;

  const handleDrawerItemClick = React.useCallback(
    () => handleDrawerClose && setTimeout(handleDrawerClose, 10),
    [handleDrawerClose],
  );

  return (
    <div className={classnames(classes.root, className)} {...props}>
      <div className={classes.wrapper}>
        <div className={classes.actionsPrimary}>
          {secondaryAppBarData
            ? map(
                secondaryAppBarData?.data?.appbar_actions,
                (action, index: number) =>
                  action && action.action_label && action.action_label.text ? (
                    <DrawerItem
                      key={index}
                      component={FieldLink}
                      field={action.action_link}
                      label={action.action_label.text}
                      onClick={handleDrawerItemClick}
                      menuLink={action.action_menu_relation}
                    />
                  ) : null,
              )
            : map(
                siteInfo?.data?.appbar_secondary_actions,
                (item, index: number) =>
                  item &&
                  item.action_label &&
                  item.action_label.text &&
                  item.action_link ? (
                    <DrawerItem
                      key={index}
                      component={FieldLink}
                      field={item.action_link}
                      label={item.action_label.text}
                      onClick={handleDrawerItemClick}
                      menuLink={item.action_menu_relation}
                    />
                  ) : null,
              )}
        </div>
        <div className={classes.actionsSecondary}>
          {map(siteInfo?.data?.appbar_primary_actions, (item, index: number) =>
            item &&
            item.action_label &&
            item.action_label.text &&
            item.action_link ? (
              <DrawerItem
                key={index}
                component={FieldLink}
                field={item.action_link}
                label={item.action_label.text}
                onClick={handleDrawerItemClick}
                classes={React.useMemo(
                  () => ({
                    text: classes.drawerItemText,
                  }),
                  [classes],
                )}
              />
            ) : null,
          )}
        </div>
      </div>
      <div className={classes.actionsTertiary}>
        <SelectorLanguagePicker />
        <SocialLinks
          links={siteInfo?.data?.social_links}
          displayAlternateIcons
          classes={React.useMemo(
            () => ({
              root: classes.socialLinks,
              iconButton: classes.socialLink,
            }),
            [classes],
          )}
        />
      </div>
    </div>
  );
};

Drawer.defaultProps = {
  className: undefined,
  closeDrawer: undefined,
  closeDrawerOnClick: undefined,
  position: undefined,
};

export default withStyles<*, *, Props>(styles)(Drawer);
