// @flow
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import blue from '@material-ui/core/colors/blue';
import type { StyleRule } from '@material-ui/core/styles/withStyles';
import omit from 'lodash/fp/omit';

import * as variants from '~plugins/material-ui/modules/snackbar/config/variants';
import type { Styles, Props } from './types';

const styles: Styles = theme => ({
  root: {},
  icon: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    '& p, & ul, & ol': {
      margin: `0.5em 0`,
    },
    '& a': {
      color: 'inherit',
    },
  },
  ...({
    [variants.default]: {},
    [variants.success]: {
      backgroundColor: green[600],
    },
    [variants.info]: {
      backgroundColor: blue[400],
    },
    [variants.error]: {
      backgroundColor: theme.palette.error.dark,
    },
    [variants.warning]: {
      backgroundColor: amber[700],
    },
  }: $ObjMap<typeof variants, () => StyleRule<Props>>),
});

export default styles;

export const omitOwnClasses = omit([
  'icon',
  'message',
  ...Object.keys(variants),
]);
