// @flow
import type { SimplePaletteColorOptions } from '@material-ui/core/styles/createPalette';
import omitBy from 'lodash/fp/omitBy';
import isNil from 'lodash/fp/isNil';

export type Config = $ObjMap<SimplePaletteColorOptions, <V>() => ?V>;

export default function sanitizePaletteColorOptions(
  colorOptions: Config,
): SimplePaletteColorOptions | void {
  const sanitizedColorOptions = omitBy(
    option => isNil(option) || option === '',
  )(colorOptions);
  return sanitizedColorOptions.main ? sanitizedColorOptions : undefined;
}
