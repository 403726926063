// @flow
import type { Styles } from './types';

const styles: Styles = theme => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'grid',
    gridTemplateRows: '1fr auto',
    alignContent: 'space-between',
    backgroundColor: theme.palette.background.default,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  actionsPrimary: {
    display: 'flex',
    flexDirection: 'column',
    height: 'fit-content',
    padding: theme.spacing(2, 0, 4, 0),
  },
  actionsSecondary: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 auto',
    background: '#f8f8f8',
    padding: theme.spacing(4, 0),
  },
  actionsTertiary: {
    display: 'flex',
    flexDirection: 'column',
    background: '#f8f8f8',
    padding: theme.spacing(0, 3.5, 3, 3.5),
  },
  accountLink: {
    width: 'fit-content',
  },
  socialLinks: {
    display: 'flex',
    gap: '24px',
    marginTop: theme.spacing(3),
  },
  socialLink: {
    padding: 0,
    color: '#232323',
    '& svg': {
      width: 20,
      height: 20,
    },
  },
  drawerItemText: {
    fontSize: theme.typography.body2.fontSize,
  },
});

export default styles;
