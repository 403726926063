// @flow
import * as React from 'react';
import SvgIcon, { type SvgIconProps } from '@material-ui/core/SvgIcon';

export type Props = SvgIconProps;

const YoutubeAltIcon = ({ ...props }: Props): React.Node => {
  return (
    <SvgIcon
      width="100px"
      height="70px"
      viewBox="0 0 100 70"
      fill="currentColor"
      {...props}
    >
      <path d="M98 11c2 7.7 2 24 2 24s0 16.3-2 24a12.5 12.5 0 01-9 9c-7.7 2-39 2-39 2s-31.3 0-39-2a12.5 12.5 0 01-9-9c-2-7.7-2-24-2-24s0-16.3 2-24c1.2-4.4 4.6-7.8 9-9 7.7-2 39-2 39-2s31.3 0 39 2c4.4 1.2 7.8 4.6 9 9zM40 50l26-15-26-15v30z" />
    </SvgIcon>
  );
};

export default React.memo<Props>(YoutubeAltIcon);
