// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-templates-all-stories-page-jsx": () => import("./../../../src/pages-templates/AllStoriesPage.jsx" /* webpackChunkName: "component---src-pages-templates-all-stories-page-jsx" */),
  "component---src-pages-templates-brand-page-jsx": () => import("./../../../src/pages-templates/BrandPage.jsx" /* webpackChunkName: "component---src-pages-templates-brand-page-jsx" */),
  "component---src-pages-templates-home-page-jsx": () => import("./../../../src/pages-templates/HomePage.jsx" /* webpackChunkName: "component---src-pages-templates-home-page-jsx" */),
  "component---src-pages-templates-location-page-jsx": () => import("./../../../src/pages-templates/LocationPage.jsx" /* webpackChunkName: "component---src-pages-templates-location-page-jsx" */),
  "component---src-pages-templates-prismic-preview-page-jsx": () => import("./../../../src/pages-templates/PrismicPreviewPage.jsx" /* webpackChunkName: "component---src-pages-templates-prismic-preview-page-jsx" */),
  "component---src-pages-templates-redirect-redirect-page-jsx": () => import("./../../../src/pages-templates/redirect/RedirectPage.jsx" /* webpackChunkName: "component---src-pages-templates-redirect-redirect-page-jsx" */),
  "component---src-pages-templates-stories-page-jsx": () => import("./../../../src/pages-templates/StoriesPage.jsx" /* webpackChunkName: "component---src-pages-templates-stories-page-jsx" */),
  "component---src-pages-templates-story-page-jsx": () => import("./../../../src/pages-templates/StoryPage.jsx" /* webpackChunkName: "component---src-pages-templates-story-page-jsx" */),
  "component---src-pages-templates-travel-detail-page-jsx": () => import("./../../../src/pages-templates/TravelDetailPage.jsx" /* webpackChunkName: "component---src-pages-templates-travel-detail-page-jsx" */),
  "component---src-pages-templates-travel-page-jsx": () => import("./../../../src/pages-templates/TravelPage.jsx" /* webpackChunkName: "component---src-pages-templates-travel-page-jsx" */)
}

