// @flow
import * as React from 'react';
import { useIntl } from 'react-intl';
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';

import classnames from 'classnames';
import map from 'lodash/map';
import compact from 'lodash/compact';

import paymentsImage from '../../../static/new-payments.png';
import Form from '~plugins/form/components/Form';
import useSiteInfo from '~hooks/useSiteInfo';
import RichText from '~plugins/prismic/components/RichText';
import SocialLinks from '~components/SocialLinks';
import getMenuType from '../../helpers/getMenuType';

import type { Props } from './types';
import styles from './styles';
import useFooterCopyright from './hooks/useFooterCopyright';
import FooterMenu from './FooterMenu';
import messages from '../../messages';
import ArrowIcon from '../icons/ArrowIcon';
import SelectorLanguagePicker from '../i18n/SelectorLanguagePicker';

const Footer = ({ className, classes, ...props }: Props): React.Node => {
  const siteInfo = useSiteInfo();
  const copyright = useFooterCopyright();
  const intl = useIntl();

  const renderedItems = React.useMemo(
    () =>
      compact(
        map(siteInfo?.data?.footer_actions, (item, index: number) => {
          const menu = getMenuType(item?.action_menu_relation);
          return menu ? (
            <Grid item xs={12} sm={3} key={index}>
              <FooterMenu
                menuTitle={menu.data?.name}
                menuLinks={menu.data?.menu_actions}
              />
            </Grid>
          ) : null;
        }),
      ),
    [siteInfo?.data?.footer_actions],
  );

  return (
    <div className={classnames(classes.root, className)} {...props}>
      <Container className={classes.wrapper}>
        <Grid
          container
          spacing={4}
          justify="space-between"
          className={classes.primaryContent}
        >
          <Grid item xs={12} sm={4} md={5}>
            <SocialLinks
              links={siteInfo?.data?.social_links}
              classes={React.useMemo(
                () => ({
                  root: classes.socialLinks,
                  iconButton: classes.socialLink,
                }),
                [classes],
              )}
            />
          </Grid>
          <Grid item xs={12} sm={8} md={7} className={classes.formWrapper}>
            <Form name="Subscribe" {...props} className={classes.form}>
              <TextField
                id="outlined-adornment-password"
                type="email"
                name="email"
                variant="outlined"
                placeholder={intl.formatMessage(messages.newsletterFormInput)}
                fullWidth
                className={classes.textField}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        type="submit"
                        aria-label={intl.formatMessage(
                          messages.newsletterFormButton,
                        )}
                        className={classes.button}
                      >
                        <ArrowIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Form>
          </Grid>

          <Grid
            container
            item
            xs={12}
            spacing={4}
            className={classes.menuWrapper}
          >
            {renderedItems}
          </Grid>
          <Grid item xs={12}>
            <div className={classes.separator}>{}</div>
          </Grid>
          <Grid item xs={12} className={classes.bottomMenuWrapper}>
            <SelectorLanguagePicker />
            <img
              src={paymentsImage}
              alt="payments"
              className={classes.paymentsImage}
            />
          </Grid>
          <Grid item xs={12}>
            <RichText {...copyright} className={classes.copyright} />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

Footer.defaultProps = {
  className: undefined,
};

export default withStyles<*, *, Props>(styles)(Footer);
