// @flow
import * as React from 'react';
import MuiSnackbar from '@material-ui/core/Snackbar';
import withStyles from '@material-ui/core/styles/withStyles';

import RichText from '~plugins/prismic/components/RichText';
import * as variants from '~plugins/material-ui/modules/snackbar/config/variants';

import defaultIcons from './defaultIcons';
import styles, { omitOwnClasses } from './styles';
import type { Props } from './types';

const Snackbar = ({
  message,
  classes,
  variant,
  Icon,
  ...props
}: Props): React.Node => {
  const IconComponent =
    Icon === null
      ? null
      : Icon || defaultIcons[variant || variants.default] || null;

  return (
    <MuiSnackbar
      {...props}
      classes={React.useMemo(() => omitOwnClasses(classes), [classes])}
      ContentProps={{
        className: classes[variant || variants.default],
        'aria-describedby': 'snackbar-message',
      }}
      message={
        <div id="snackbar-message" className={classes.message}>
          {IconComponent ? <IconComponent className={classes.icon} /> : null}
          {typeof message === 'string' ? <RichText html={message} /> : message}
        </div>
      }
    />
  );
};

Snackbar.defaultProps = {
  variant: variants.default,
  className: undefined,
  onClose: undefined,
};

const StyledSnackbar = withStyles<*, *, Props>(styles)(Snackbar);

export default StyledSnackbar;

