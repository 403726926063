// @flow

import type { SiteMetadata$Config } from '../src/plugins/site/types';

const getEnvVar = require('../src/plugins/site/helpers/getEnvVar');
const newDate = require('../src/helpers/newDate');

/* GatsbyStarterConfig: site metadata (shared config) */
module.exports = ({
  siteName: getEnvVar('SITE_NAME') ?? '',
  siteUrl: getEnvVar('SITE_URL') ?? '',
  title: getEnvVar('TITLE') ?? '',
  description: getEnvVar('DESCRIPTION') ?? '',
  author: {
    name: `Origen Studio <hello@origen.studio>`,
    email: `hello@origen.studio`,
  },
  image: {
    url: 'https://global.velodrom.cc/velodrom_thumbnail.jpg', // acts as default image
    alt: 'brand image',
    width: '512',
    height: '512',
  },
  organization: {
    name: `Velodrom.cc`,
    url: `https://velodrom.cc`,
    email: `info@velodrom.cc`,
    logo: {
      url: 'https://global.velodrom.cc/velodrom_thumbnail.jpg', // acts as default image
      alt: 'brand image',
      width: '512',
      height: '512',
    },
  },

  datePublished: newDate().toISOString(),
  dateModified: newDate().toISOString(),
  social: {
    twitter: '',
    fbAppID: '', // real app ID for the Origen Studio Gatsby Starter
  },
  baseCookieKey: getEnvVar('BASE_COOKIE_KEY') || 'gatsby-starter-default',
}: SiteMetadata$Config);
