// @flow
import * as React from 'react';
import Helmet from 'react-helmet';

import './fonts.css';

const FontsHelmet = () => (
  <Helmet>
    {/* Custom font link. files stored in the assets folder */}
    <link
      rel="preload"
      as="font"
      href="/fonts/ProximaNovaRegular.woff"
      type="font/woff"
      crossOrigin="anonymous"
    />
    <link
      rel="preload"
      as="font"
      href="/fonts/ProximaNovaSemibold.woff"
      type="font/woff"
      crossOrigin="anonymous"
    />
    <link
      rel="preload"
      href="https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500&display=swap"
    />
  </Helmet>
);

export default FontsHelmet;
