// @flow
import * as React from 'react';

import type { PrismicLinkType } from '~schema';

import { PrismicLinkTypesValues } from '../config';
import Link from '../../i18n/components/Link';
import hasLinkFieldValue from '../helpers/hasLinkFieldValue';
import {
  isFragmentLink,
  sanitizeFragmentLink,
  queryFragmentElement,
} from '../helpers/fragmentLink';

type LinkProps = React.ElementConfig<typeof Link>;

export type Props = {|
  ...$Exact<$Diff<LinkProps, { to: any }>>,
  field: ?PrismicLinkType,
  Fallback?: React.ComponentType<$Diff<LinkProps, { to: any }>>,
  fallback?: ?React.Node,
|};

const useProps = ({ field, forwardRef, onClick, ...props }: Props) => {
  const handleFragmentClick = React.useCallback(
    (event: MouseEvent) => {
      if (onClick) {
        onClick(event);
      }
      const element = queryFragmentElement(field?.url);
      if (!event.defaultPrevented && element) {
        event.preventDefault();
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start',
        });
      }
    },
    [onClick, field],
  );

  switch (field?.link_type) {
    case PrismicLinkTypesValues.Document:
      return {
        target: field?.target || undefined,
        onClick,
        ...props,
        Component: Link,
        to: field?.url || '#',
        localized: true,
        forwardRef,
      };
    default:
      return {
        target: field?.target || undefined,
        rel: 'noopener noreferrer',
        onClick,
        ...props,
        Component: 'a',
        href: field?.url,
        ref: forwardRef,
        ...(isFragmentLink(field?.url)
          ? {
              href: sanitizeFragmentLink(field?.url),
              target: undefined,
              onClick: handleFragmentClick,
            }
          : {}),
      };
  }
};

const FieldLink = React.forwardRef<Props, *>(
  ({ field, Fallback, fallback = null, ...props }, ref) => {
    if (!hasLinkFieldValue(field)) {
      return Fallback ? <Fallback {...props} ref={ref} /> : fallback;
    }
    const { Component, ...linkProps } = useProps({
      field,
      forwardRef: ref,
      ...props,
    });

    return <Component {...linkProps} />;
  },
);

FieldLink.displayName = 'FieldLink';

export { hasLinkFieldValue };
export default FieldLink;
